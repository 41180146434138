// ** React Imports
import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';
// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** Ripple Button
import '@components/ripple-button';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import '@fonts/feather/iconfont.css';
import '@styles/core.scss';
import '@assets/scss/style.scss';
import UILoader from '@components/ui-loader';
// import Spinner from '@components/spinner/Fallback-spinner';

// ** Lazy load app
const LazyApp = lazy(() => import('./app/App'));

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Suspense fallback={(
      <UILoader
        className={'full-page hide-text'}
        blocking
      />
    )}
    >
      <LazyApp />
    </Suspense>
  );
};

renderApp();
