import classnames from 'classnames';
import Proptypes from 'prop-types';
import { Fragment } from 'react';

// ** Styles
import './ui-loader.scss';
import ComponentSpinner from '@components/spinner/Loading-spinner';

const UILoader = (props) => {
  const {
    children,
    renderEmpty,
    emptyResult,
    blocking,
    loader,
    className,
    tag,
    overlayColor
  } = props;

  const Tag = tag;

  return (
    <Tag className={classnames({ [className]: className, show: blocking })}>
      {!blocking && !(Boolean(emptyResult) && Boolean(renderEmpty)) && children}
      {!blocking && (Boolean(emptyResult) && Boolean(renderEmpty)) && renderEmpty}
      {blocking ? (
        <Fragment>
          <div
            /*eslint-disable */
            {...(blocking && overlayColor ? { style: { backgroundColor: overlayColor } } : {})}
          />
          {loader}
        </Fragment>
      ) : null}
    </Tag>
  )
};

export default UILoader;

UILoader.defaultProps = {
  tag: 'div',
  blocking: false,
  loader:  <ComponentSpinner />
};

UILoader.propTypes = {
  tag: Proptypes.string,
  loader: Proptypes.any,
  className: Proptypes.string,
  overlayColor: Proptypes.string,
  blocking: Proptypes.bool.isRequired,
  renderEmpty: Proptypes.element,
  emptyResult: Proptypes.bool,
  children: Proptypes.any
};
