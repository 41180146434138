import { isUndefined } from 'lodash';
import Lottie from 'lottie-react';

import heartbeat from '@assets/lottie/135913-heartbeat-blue.json';

type ComponentSpinnerProps = {
// TODO: make this non optional when have time to go through and update all usages
  loading?: boolean
};

const ComponentSpinner = ({ loading }: ComponentSpinnerProps) => {
  let isLoading = loading;
  if (isUndefined(loading)){
    isLoading = true;
  }
  return isLoading && (
    <div className={'spinner-wrapper zindex-1 w-100 h-100'}>
      <div className='fallback-spinner'>
        <div style={{ height: '25em' }}>
          <Lottie
            className={'h-100'}
            animationData={heartbeat}
            loop
          />
          <h4 className={'d-flex flex-grow-1 text-black-50 loadingText'}>Loading...</h4>
        </div>
      </div>
    </div>
  );
};

export default ComponentSpinner;
